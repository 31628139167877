import React from 'react';
import { useSx, View, Text } from 'dripsy';

interface ProgressBar {
  total?: number;
  covered?: number;
  progressColor?: string;
  showAnalytics?: boolean;
  backgroundColor?: string;
  showProgressInPercent?: boolean;
  thickness?: number;
  calculatePercentage?: boolean;
  roundSize?: number;
  decimalNumber?: number;
}

export function ProgressBar({
  total = 0,
  covered = 0,
  progressColor = '$secondary',
  showAnalytics = true,
  backgroundColor = '#E0F4F3',
  showProgressInPercent = false,
  thickness = 5,
  calculatePercentage = true,
  roundSize = 20,
  decimalNumber = 0
}: ProgressBar) {
  const sx = useSx();
  const progress = total > 0 ?
    (calculatePercentage ?
      ((covered / total) * 100).toFixed(decimalNumber) : covered.toFixed(decimalNumber))
    : 0;
  return (
    <View
      style={sx({
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
      })}
    >
      <View
        style={[
          sx({
            backgroundColor: backgroundColor,
            height: thickness || 5,
            borderRadius: roundSize || 20,
            flex: 1,
            marginRight: [3, 4],
          }),
        ]}
      >
        <View
          style={sx({
            width: `${covered > total ? (calculatePercentage ? '100' : progress) : progress}%`,
            backgroundColor: progressColor,
            height: thickness || 5,
            borderRadius: roundSize || 20,
          })}
        ></View>
      </View>

      {showAnalytics && (
        <View
          style={sx({
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          })}
        >
          <Text
            style={[
              sx({
                fontSize: 15,
                color: '$fontGrey',
              }),
            ]}
          >
            Pages {covered > total ? total : covered} / {total}
          </Text>
        </View>
      )}
      {showProgressInPercent && <Text numberOfLines={1}>{`${progress}%`}</Text>}
    </View>
  );
}
